import MealItem from './MealItem.jsx';
import useHttp from './http hook/useHttp.js';
import Error from './Error.jsx';

const requestConfig = {};

export default function Meals() {
  const { data: loadedMeals, isLoading, error } = useHttp('/meals', requestConfig, []);

  if (isLoading) {
    return <p className="center">Fetching meals...</p>;
  }

  if (error) {
    return <Error title="Failed to fetch meals" message={error} />;
  }

  // if(!data) {
  //     return<p>No meals found</p>
  // }
  return (
    <ul id="meals">
      {loadedMeals.map((meal) => (
        <MealItem key={meal.id} meal={meal} />
      ))}
    </ul>
  );
}
